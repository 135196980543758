@import url(https://fonts.googleapis.com/css2?family=Dancing+Script:wght@600&display=swap);
/* Document
 * ========================================================================== */
/**
 * 1. Correct the line height in all browsers.
 * 2. Prevent adjustments of font size after orientation changes in
 *    IE on Windows Phone and in iOS.
 */
html {
  line-height: 1.15; /* 1 */
  -ms-text-size-adjust: 100%; /* 2 */
  -webkit-text-size-adjust: 100%; /* 2 */
}
/* Sections
 * ========================================================================== */
/**
 * Correct the font size and margin on `h1` elements within `section` and
 * `article` contexts in Chrome, Edge, Firefox, and Safari.
 */
h1 {
  font-size: 2em;
  margin: 0.67em 0;
}
/* Grouping content
 * ========================================================================== */
/**
 * Remove the margin on nested lists in Chrome, Edge, IE, and Safari.
 */
dl dl,
dl ol,
dl ul,
ol dl,
ul dl {
  margin: 0;
}
/**
 * 1. Add the correct box sizing in Firefox.
 * 2. Show the overflow in Edge 18- and IE.
 */
hr {
  box-sizing: content-box; /* 1 */
  height: 0; /* 1 */
}
/**
 * 1. Correct the inheritance and scaling of font size in all browsers.
 * 2. Correct the odd `em` font sizing in all browsers.
 */
pre {
  font-family: monospace, monospace; /* 1 */
  font-size: 1em; /* 2 */
}
/* Text-level semantics
 * ========================================================================== */
/**
 * Add the correct text decoration in Edge 18-, IE, and Safari.
 */
abbr[title] {
  text-decoration: underline;
  text-decoration: underline dotted;
}
/**
 * Add the correct font weight in Chrome, Edge, and Safari.
 */
b,
strong {
  font-weight: bolder;
}
/**
 * 1. Correct the inheritance and scaling of font size in all browsers.
 * 2. Correct the odd `em` font sizing in all browsers.
 */
code,
kbd,
samp {
  font-family: monospace, monospace; /* 1 */
  font-size: 1em; /* 2 */
}
/**
 * Add the correct font size in all browsers.
 */
small {
  font-size: 80%;
}
/* Embedded content
 * ========================================================================== */
/* Forms
 * ========================================================================== */
/**
 * Remove the margin on controls in Safari.
 */
button,
input,
select {
  margin: 0;
}
/**
 * 1. Show the overflow in IE.
 * 2. Remove the inheritance of text transform in Edge 18-, Firefox, and IE.
 */
button {
  text-transform: none; /* 2 */
}
/**
 * Correct the inability to style buttons in iOS and Safari.
 */
button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
}
/**
 * Correct the padding in Firefox.
 */
fieldset {
  padding: 0.35em 0.75em 0.625em;
}
/**
 * 1. Add the correct display in Edge 18- and IE.
 * 2. Add the correct vertical alignment in Chrome, Edge, and Firefox.
 */
progress {
  vertical-align: baseline; /* 2 */
}
/**
 * Remove the inheritance of text transform in Firefox.
 */
select {
  text-transform: none;
}
/**
 * 1. Remove the margin in Firefox and Safari.
 * 2. Remove the default vertical scrollbar in IE.
 */
textarea {
  margin: 0; /* 1 */
}
/**
 * 1. Correct the odd appearance in Chrome, Edge, and Safari.
 * 2. Correct the outline style in Safari.
 */
[type="search"] {
  -webkit-appearance: textfield; /* 1 */
  outline-offset: -2px; /* 2 */
}
/**
 * Correct the cursor style of increment and decrement buttons in Safari.
 */
::-webkit-inner-spin-button,
::-webkit-outer-spin-button {
  height: auto;
}
/**
 * Correct the text style of placeholders in Chrome, Edge, and Safari.
 */
::-webkit-input-placeholder {
  color: inherit;
  opacity: 0.54;
}
/**
 * Remove the inner padding in Chrome, Edge, and Safari on macOS.
 */
::-webkit-search-decoration {
  -webkit-appearance: none;
}
/**
 * 1. Correct the inability to style upload buttons in iOS and Safari.
 * 2. Change font properties to `inherit` in Safari.
 */
::-webkit-file-upload-button {
  -webkit-appearance: button; /* 1 */
  font: inherit; /* 2 */
}
/**
 * Remove the inner border and padding of focus outlines in Firefox.
 */
::-moz-focus-inner {
  border-style: none;
  padding: 0;
}
/**
 * Restore the focus outline styles unset by the previous rule in Firefox.
 */
:-moz-focusring {
  outline: 1px dotted ButtonText;
}
/**
 * Remove the additional :invalid styles in Firefox.
 */
:-moz-ui-invalid {
  box-shadow: none;
}
/* Interactive
 * ========================================================================== */
/*
 * Add the correct display in Edge 18- and IE.
 */
details {
  display: block;
}
/*
 * Add the correct styles in Edge 18-, IE, and Safari.
 */
dialog {
  background-color: white;
  border: solid;
  color: black;
  display: block;
  height: -moz-fit-content;
  height: -webkit-fit-content;
  height: fit-content;
  left: 0;
  margin: auto;
  padding: 1em;
  position: absolute;
  right: 0;
  width: -moz-fit-content;
  width: -webkit-fit-content;
  width: fit-content;
}
dialog:not([open]) {
  display: none;
}
/*
 * Add the correct display in all browsers.
 */
summary {
  display: list-item;
}
/* Scripting
 * ========================================================================== */
/* User interaction
 * ========================================================================== */

html,
body,
#root,
.page {
  height: 100%;
  margin: 0;
}

.page {
  display: flex;
  align-items: center;
  justify-content: center;

  background: lightblue;
}

.envelope {
  position: relative;

  width: 98px;
  height: 66px;
  background: #c94548;
  border-radius: 0 0 5px 5px;
  box-shadow: 0 0 1px #c94548 inset;

  -webkit-transform: scale(2);

          transform: scale(2);

  pointer-events: none;
}

.envelope:before,
.envelope:after {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  border: 0 solid transparent;
  border-width: 33px 49px;
}

.envelope:before {
  z-index: 0;

  border-bottom-color: #c94548;
  top: -100%;
  left: 0px;
}

.envelope:after {
  z-index: 2;

  border-right-color: #ed4c50;
  border-left-color: #fa565a;
  border-bottom-color: #fa565a;
  top: 0;
  border-radius: 0 0 5px 5px;
  -webkit-transform: rotate(360deg);
          transform: rotate(360deg);
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.25);
}

@-webkit-keyframes highlightPaper {
  5%,
  15% {
    -webkit-transform: translateY(-30px);
            transform: translateY(-30px);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
  }
  0%,
  10%,
  20% {
    -webkit-transform: translateY(-20px);
            transform: translateY(-20px);
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
  }
}

@keyframes highlightPaper {
  5%,
  15% {
    -webkit-transform: translateY(-30px);
            transform: translateY(-30px);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
  }
  0%,
  10%,
  20% {
    -webkit-transform: translateY(-20px);
            transform: translateY(-20px);
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
  }
}

.paper {
  background: #fff;
  width: 87px;
  height: 66px;
  margin: 0 auto;
  border-radius: 5px;

  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.25);

  position: relative;

  z-index: 1;

  overflow: hidden;

  pointer-events: auto;

  -webkit-transform: translateY(-20px);

          transform: translateY(-20px);
}

.paper:not(.clicked) {
  cursor: pointer;

  -webkit-animation: highlightPaper 2.5s;

          animation: highlightPaper 2.5s;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
}

@-webkit-keyframes movePaperOutOfEnvelope {
  50% {
    -webkit-transform: translateY(-80px scale(1));
            transform: translateY(-80px scale(1));
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
    z-index: 1;
  }
  50.001% {
    -webkit-transform: translateY(-80px) scale(1);
            transform: translateY(-80px) scale(1);
    z-index: 5;
  }
  100% {
    -webkit-transform: translateY(0px) scale(4);
            transform: translateY(0px) scale(4);
    z-index: 5;
  }
}

@keyframes movePaperOutOfEnvelope {
  50% {
    -webkit-transform: translateY(-80px scale(1));
            transform: translateY(-80px scale(1));
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
    z-index: 1;
  }
  50.001% {
    -webkit-transform: translateY(-80px) scale(1);
            transform: translateY(-80px) scale(1);
    z-index: 5;
  }
  100% {
    -webkit-transform: translateY(0px) scale(4);
            transform: translateY(0px) scale(4);
    z-index: 5;
  }
}

.paper.clicked {
  -webkit-animation: movePaperOutOfEnvelope 1s forwards;
          animation: movePaperOutOfEnvelope 1s forwards;
}

.placeholder {
  position: absolute;
  inset: 0;
}

.placeholder:before,
.placeholder:after {
  content: "";
  position: absolute;
  left: 12px;
  background: #e3f1fc;
  height: 4px;
  border-radius: 5px;
}

.placeholder:before {
  top: 12px;
  width: 31px;
}

.placeholder:after {
  right: 12px;
  top: 26px;
  box-shadow: 0 8px 0 #e3f1fc, 0 16px 0 #e3f1fc, 0 24px 0 #e3f1fc;
}

@-webkit-keyframes fadeOutPlaceholder {
  60% {
    opacity: 1;
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
  }
  80%,
  100% {
    opacity: 0;
  }
}

@keyframes fadeOutPlaceholder {
  60% {
    opacity: 1;
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
  }
  80%,
  100% {
    opacity: 0;
  }
}

.paper.clicked > .placeholder {
  -webkit-animation: fadeOutPlaceholder 3s forwards;
          animation: fadeOutPlaceholder 3s forwards;
}

.content {
  position: absolute;
  inset: 0;

  padding: 8px 3.95px;

  font-family: "Dancing Script", cursive;
  font-size: 3.65px;

  opacity: 0;
}

@-webkit-keyframes fadeInContent {
  80% {
    opacity: 0;
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadeInContent {
  80% {
    opacity: 0;
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
  }
  100% {
    opacity: 1;
  }
}

.paper.clicked > .content {
  -webkit-animation: fadeInContent 3s forwards;
          animation: fadeInContent 3s forwards;
}

.content > .section {
  margin-bottom: 4px;
}

.content > .present {
  margin-top: 6px;

  display: flex;
  justify-content: center;
}

@-webkit-keyframes presentShake {
  0%,
  32% {
    -webkit-transform: rotate(0);
            transform: rotate(0);
    -webkit-animation-timing-function: ease;
            animation-timing-function: ease;
  }
  4%,
  12%,
  20%,
  28% {
    -webkit-transform: rotate(-5deg);
            transform: rotate(-5deg);
    -webkit-animation-timing-function: ease;
            animation-timing-function: ease;
  }
  8%,
  16%,
  24% {
    -webkit-transform: rotate(+5deg);
            transform: rotate(+5deg);
    -webkit-animation-timing-function: ease;
            animation-timing-function: ease;
  }
}

@keyframes presentShake {
  0%,
  32% {
    -webkit-transform: rotate(0);
            transform: rotate(0);
    -webkit-animation-timing-function: ease;
            animation-timing-function: ease;
  }
  4%,
  12%,
  20%,
  28% {
    -webkit-transform: rotate(-5deg);
            transform: rotate(-5deg);
    -webkit-animation-timing-function: ease;
            animation-timing-function: ease;
  }
  8%,
  16%,
  24% {
    -webkit-transform: rotate(+5deg);
            transform: rotate(+5deg);
    -webkit-animation-timing-function: ease;
            animation-timing-function: ease;
  }
}

.content > .present > a {
  -webkit-animation: presentShake 2.5s;
          animation: presentShake 2.5s;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
}

