@import url("https://fonts.googleapis.com/css2?family=Dancing+Script:wght@600&display=swap");
@import-normalize;

html,
body,
#root,
.page {
  height: 100%;
  margin: 0;
}

.page {
  display: flex;
  align-items: center;
  justify-content: center;

  background: lightblue;
}

.envelope {
  position: relative;

  width: 98px;
  height: 66px;
  background: #c94548;
  border-radius: 0 0 5px 5px;
  box-shadow: 0 0 1px #c94548 inset;

  transform: scale(2);

  pointer-events: none;
}

.envelope:before,
.envelope:after {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  border: 0 solid transparent;
  border-width: 33px 49px;
}

.envelope:before {
  z-index: 0;

  border-bottom-color: #c94548;
  top: -100%;
  left: 0px;
}

.envelope:after {
  z-index: 2;

  border-right-color: #ed4c50;
  border-left-color: #fa565a;
  border-bottom-color: #fa565a;
  top: 0;
  border-radius: 0 0 5px 5px;
  transform: rotate(360deg);
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.25);
}

@keyframes highlightPaper {
  5%,
  15% {
    transform: translateY(-30px);
    animation-timing-function: ease-in;
  }
  0%,
  10%,
  20% {
    transform: translateY(-20px);
    animation-timing-function: ease-out;
  }
}

.paper {
  background: #fff;
  width: 87px;
  height: 66px;
  margin: 0 auto;
  border-radius: 5px;

  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.25);

  position: relative;

  z-index: 1;

  overflow: hidden;

  pointer-events: auto;

  transform: translateY(-20px);
}

.paper:not(.clicked) {
  cursor: pointer;

  animation: highlightPaper 2.5s;
  animation-iteration-count: infinite;
}

@keyframes movePaperOutOfEnvelope {
  50% {
    transform: translateY(-80px scale(1));
    animation-timing-function: ease-out;
    z-index: 1;
  }
  50.001% {
    transform: translateY(-80px) scale(1);
    z-index: 5;
  }
  100% {
    transform: translateY(0px) scale(4);
    z-index: 5;
  }
}

.paper.clicked {
  animation: movePaperOutOfEnvelope 1s forwards;
}

.placeholder {
  position: absolute;
  inset: 0;
}

.placeholder:before,
.placeholder:after {
  content: "";
  position: absolute;
  left: 12px;
  background: #e3f1fc;
  height: 4px;
  border-radius: 5px;
}

.placeholder:before {
  top: 12px;
  width: 31px;
}

.placeholder:after {
  right: 12px;
  top: 26px;
  box-shadow: 0 8px 0 #e3f1fc, 0 16px 0 #e3f1fc, 0 24px 0 #e3f1fc;
}

@keyframes fadeOutPlaceholder {
  60% {
    opacity: 1;
    animation-timing-function: ease-out;
  }
  80%,
  100% {
    opacity: 0;
  }
}

.paper.clicked > .placeholder {
  animation: fadeOutPlaceholder 3s forwards;
}

.content {
  position: absolute;
  inset: 0;

  padding: 8px 3.95px;

  font-family: "Dancing Script", cursive;
  font-size: 3.65px;

  opacity: 0;
}

@keyframes fadeInContent {
  80% {
    opacity: 0;
    animation-timing-function: ease-in;
  }
  100% {
    opacity: 1;
  }
}

.paper.clicked > .content {
  animation: fadeInContent 3s forwards;
}

.content > .section {
  margin-bottom: 4px;
}

.content > .present {
  margin-top: 6px;

  display: flex;
  justify-content: center;
}

@keyframes presentShake {
  0%,
  32% {
    transform: rotate(0);
    animation-timing-function: ease;
  }
  4%,
  12%,
  20%,
  28% {
    transform: rotate(-5deg);
    animation-timing-function: ease;
  }
  8%,
  16%,
  24% {
    transform: rotate(+5deg);
    animation-timing-function: ease;
  }
}

.content > .present > a {
  animation: presentShake 2.5s;
  animation-iteration-count: infinite;
}
